// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-antimicrobial-door-protection-js": () => import("./../../../src/pages/antimicrobial-door-protection.js" /* webpackChunkName: "component---src-pages-antimicrobial-door-protection-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-site-survey-js": () => import("./../../../src/pages/site-survey.js" /* webpackChunkName: "component---src-pages-site-survey-js" */),
  "component---src-pages-terms-privacy-js": () => import("./../../../src/pages/terms-privacy.js" /* webpackChunkName: "component---src-pages-terms-privacy-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-download-js": () => import("./../../../src/templates/download.js" /* webpackChunkName: "component---src-templates-download-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-washspaces-section-js": () => import("./../../../src/templates/washspaces-section.js" /* webpackChunkName: "component---src-templates-washspaces-section-js" */)
}

